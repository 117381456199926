import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';

const List = styled.ul`
  padding: 20px;
  margin-top: 19px;
  background-color: #fff;
`;

const Item = styled.li`
  &:not(:first-of-type) {
    padding-top: 16px;
    margin-top: 12px;
    border-top: 1px solid #eee;
  }
`;

// biome-ignore lint/suspicious/noShadowRestrictedNames: コンポーネント内でDateクラスを直接使わない
const Date = styled.p`
  font-size: 12px;
  color: #666;
`;

const ArticleLink = styled(Link)`
  display: inline-block;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.7;
  color: #1b1b1b;
  text-decoration: none;
`;

const query = graphql`
  query LatestArticles {
    allMarkdownRemark(
      filter: { fields: { itemType: { eq: "article" } } }
      limit: 5
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          date(formatString: "YYYY年M月D日")
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;

const SidebarLatestArticles: React.FC = () => {
  const { allMarkdownRemark } =
    useStaticQuery<GatsbyTypes.LatestArticlesQuery>(query);

  return (
    <List>
      {allMarkdownRemark.nodes.map((node, index) => (
        <Item key={node.frontmatter?.title || index}>
          <Date>{node.frontmatter?.date}</Date>
          <ArticleLink to={node.fields?.slug || ''}>
            {node.frontmatter?.title}
          </ArticleLink>
        </Item>
      ))}
    </List>
  );
};

export default SidebarLatestArticles;
