import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import CorpLogo from '@/atoms/CorpLogo';
import FooterNavLinks from '@/molecules/FooterNavLinks';
import { aboutLinks, companyLinks, LinkType } from '@/services/links';

const query = graphql`
  query FooterNav {
    allSiteCategory {
      nodes {
        slug
        title
      }
    }
    allSiteTag(filter: { is_pickup: { eq: true } }) {
      nodes {
        slug
        title
      }
    }
  }
`;

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const LinksWrapper = styled.div`
  max-width: 480px;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(4) {
    flex-shrink: 0;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }

  @media (max-width: 767px) {
    max-width: initial;

    &:not(:first-child) {
      margin: 40px 0 0;
    }
  }
`;

const CorpLogoWrapper = styled.div`
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

const FooterNav: React.FC = () => {
  const { allSiteTag, allSiteCategory } =
    useStaticQuery<GatsbyTypes.FooterNavQuery>(query);
  const categoryNodes = allSiteCategory.nodes as LinkType[];
  const tagNodes = allSiteTag.nodes as LinkType[];

  return (
    <Wrapper>
      <LinksWrapper>
        <FooterNavLinks heading="ABOUT" links={aboutLinks} />
      </LinksWrapper>
      <LinksWrapper>
        <FooterNavLinks heading="CATEGORY" links={categoryNodes} />
      </LinksWrapper>
      <LinksWrapper>
        <FooterNavLinks heading="PICK UP タグ" links={tagNodes} />
      </LinksWrapper>
      <LinksWrapper>
        <FooterNavLinks
          heading="運営会社（株式会社ニジボックス）"
          links={companyLinks}
          column
        />
        <CorpLogoWrapper>
          <CorpLogo white />
        </CorpLogoWrapper>
      </LinksWrapper>
    </Wrapper>
  );
};

export default FooterNav;
