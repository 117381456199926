import React from 'react';
import styled from 'styled-components';
import HeaderLogo from '@/atoms/HeaderLogo';
import HeaderDescription from '@/atoms/HeaderDescription';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DescriptionWrapper = styled.div`
  margin-left: 20px;
`;

type Props = {
  isSticky?: boolean;
};

const HeaderLeftSide: React.FC<Props> = ({ isSticky }) => (
  <Wrapper>
    <HeaderLogo isSticky={isSticky} />
    <DescriptionWrapper>
      <HeaderDescription isSticky={isSticky} />
    </DescriptionWrapper>
  </Wrapper>
);

export default HeaderLeftSide;
