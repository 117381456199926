import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MenuButton from '@/atoms/HeaderNavButton';
import HeaderLeftSide from '@/molecules/HeaderLeftSide';
import HeaderIcons from '@/molecules/HeaderIcons';
import HeaderNavSp from '@/organisms/HeaderNavSp';
import StickyHeader from '@/organisms/StickyHeader';

const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  padding: 30px 30px 25px;
  background: #fff;

  @media (max-width: 767px) {
    position: fixed;
    z-index: 100;
    width: 100vw;
    padding: 16px 20px 13px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
`;

const HeaderNavPc = styled.nav`
  @media (max-width: 767px) {
    display: none;
  }
`;

const Header: React.FC = () => {
  const headerRef = useRef<HTMLElement>(null);

  const [headerHeight, setHeaderHeight] = useState(0);
  const [active, setActive] = useState(false);

  const handleClick = () => {
    if (active) setActive(false);
    else setActive(true);
  };

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  });

  return (
    <>
      <Wrapper ref={headerRef}>
        <Inner>
          <HeaderLeftSide />
          <HeaderNavPc>
            <HeaderIcons />
          </HeaderNavPc>
          <MenuButton handleClick={handleClick} active={active} />
        </Inner>
      </Wrapper>
      <HeaderNavSp active={active} />
      <StickyHeader offset={headerHeight} />
    </>
  );
};

export default Header;
