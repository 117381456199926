import React from 'react';
import styled from 'styled-components';
import 'scroll-behavior-polyfill';

const Button = styled.button`
  position: relative;
  width: 70px;
  height: 70px;
  cursor: pointer;
  background-color: #1b1b1b;
  border: none;
  border-radius: 50%;
  outline: none;
  appearance: none;

  @media (max-width: 767px) {
    width: 52px;
    height: 52px;
  }

  &::before {
    position: absolute;
    top: 45%;
    left: 50%;
    display: block;
    width: 12px;
    height: 12px;
    content: '';
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: translate(-50%) rotate(45deg);

    @media (max-width: 767px) {
      width: 7px;
      height: 7px;
    }
  }
`;

const handleClick = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const ToTopButton: React.FC = () => (
  <Button type="button" onClick={handleClick} />
);

export default ToTopButton;
