import React from 'react';
import styled from 'styled-components';
import NavLinkHeading from '@/atoms/NavLinkHeading';
import NavLink from '@/atoms/NavLink';
import { LinkType } from '@/services/links';

const LinkList = styled.ul<Pick<Props, 'column'>>`
  @media (max-width: 767px) {
    display: ${(props) => (props.column ? 'block' : 'flex')};
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const TagLinkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 0 -10px;

  @media (max-width: 767px) {
    justify-content: space-between;
    margin: 0;
  }
`;

const LinkItem = styled.li`
  flex-basis: 50%;
  margin-top: 13px;

  &:first-child {
    margin-top: 29px;
  }

  @media (max-width: 767px) {
    margin-top: 17px;

    &:first-child {
      margin-top: 17px;
    }
  }
`;

const TagLinkItem = styled.li`
  margin: 13px 0 0 10px;

  @media (max-width: 767px) {
    flex-basis: 50%;
    margin: 17px 0 0 0;
  }
`;

const MoreLinkWrapper = styled.div`
  margin-top: 20px;
`;

type Props = {
  heading: string;
  links: LinkType[];
  column?: boolean;
};

const FooterNavLinks: React.FC<Props> = ({ heading, links, column }) => (
  <div>
    <NavLinkHeading footer>{heading}</NavLinkHeading>
    {heading === 'PICK UP タグ' ? (
      <>
        <TagLinkList>
          {links.map((link) => (
            <TagLinkItem key={link.title}>
              <NavLink heading={heading} link={link} footer />
            </TagLinkItem>
          ))}
        </TagLinkList>
        <MoreLinkWrapper>
          <NavLink
            heading=""
            link={{ title: 'タグ一覧を見る', slug: '/tag/' }}
            footer
          />
        </MoreLinkWrapper>
      </>
    ) : (
      <LinkList column={column}>
        {links.map((link) => (
          <LinkItem key={link.title}>
            <NavLink heading={heading} link={link} footer />
          </LinkItem>
        ))}
      </LinkList>
    )}
  </div>
);

export default FooterNavLinks;
