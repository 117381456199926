import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import HeaderCorpInfo from '@/molecules/HeaderCorpInfo';
import HeaderIcons from '@/molecules/HeaderIcons';
import HeaderNavLinks from '@/molecules/HeaderNavLinks';
import { aboutLinks, LinkType } from '@/services/links';

type Props = {
  active: boolean;
};

const query = graphql`
  query HeaderNavSp {
    allSiteCategory {
      nodes {
        slug
        title
      }
    }
    allSiteTag(filter: { is_pickup: { eq: true } }) {
      nodes {
        slug
        title
      }
    }
  }
`;

const Wrapper = styled.nav<Props>`
  position: fixed;
  z-index: 50;
  display: none;
  width: 100vw;
  height: 100vh;
  padding: 104px 20px 20px;
  overflow-y: scroll;
  background: #fff;

  @media (max-width: 767px) {
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const CorpWrapper = styled.div`
  margin-top: 50px;
`;

const HeaderNavSp: React.FC<Props> = ({ active }) => {
  const { allSiteTag, allSiteCategory } =
    useStaticQuery<GatsbyTypes.HeaderNavSpQuery>(query);
  const categoryNodes = allSiteCategory.nodes as LinkType[];
  const tagNodes = allSiteTag.nodes as LinkType[];

  return (
    <Wrapper active={active}>
      <HeaderNavLinks heading="ABOUT" links={aboutLinks} />
      <HeaderNavLinks heading="CATEGORY" links={categoryNodes} />
      <HeaderNavLinks heading="PICK UP タグ" links={tagNodes} />
      <IconWrapper>
        <HeaderIcons />
      </IconWrapper>
      <CorpWrapper>
        <HeaderCorpInfo />
      </CorpWrapper>
    </Wrapper>
  );
};

export default HeaderNavSp;
