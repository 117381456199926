import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HeaderLeftSide from '@/molecules/HeaderLeftSide';
import HeaderIcons from '@/molecules/HeaderIcons';

type Props = {
  offset: number;
};

const Wrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  position: fixed;
  width: 100vw;
  background: #fff;
  justify-content: center;
  z-index: 100;
  transition: top 0.3s ease;

  top: ${(props) => (props.isVisible ? '0' : '-130px')}; 
  padding: ${(props) => (props.isVisible ? '16px 20px 13px' : '30px 30px 25px')};
  box-shadow: ${(props) =>
    props.isVisible ? '0 1px 8px rgba(0, 0, 0, 0.08)' : 'none'}; 

  @media (max-width: 767px) {
    display: none;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
`;

const StickyHeader: React.FC<Props> = ({ offset }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > offset);
    };

    setIsVisible(window.scrollY > offset);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [offset]);

  return (
    <Wrapper isVisible={isVisible}>
      <Inner>
        <HeaderLeftSide isSticky />
        <HeaderIcons />
      </Inner>
    </Wrapper>
  );
};

export default StickyHeader;
