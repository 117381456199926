import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SidebarHeading from '@/atoms/SidebarHeading';
import SidebarInformation from '@/molecules/SidebarInformation';
import SidebarLatestArticles from '@/molecules/SidebarLatestArticles';
import SidebarTags from '@/molecules/SidebarTags';

type Props = {
  pathname: string;
};

const Wrapper = styled.nav`
  width: 310px;
  margin-left: 30px;

  @media (max-width: 1399px) {
    width: calc(100 / 1400 * 310vw);
    margin-left: calc(100 / 1400 * 30vw);
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: initial;
    margin: 80px 0 0 0;
  }
`;

const Inner = styled.div`
  &:not(:first-of-type) {
    margin-top: 60px;

    @media (max-width: 767px) {
      margin-top: 46px;
    }
  }
`;

const StickyInner = styled(Inner)<{ top: number }>`
  position: sticky;
  top: ${({ top }) => `${top}px`};
`;

const Sidebar: React.FC<Props> = (props: Props) => {
  const { pathname } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (!ref.current) {
        return;
      }

      const stickyHeaderHeight = 75;
      const stickyInnerHeight = ref.current.clientHeight;

      const offset = 15; // StickyInnerが追従する際の上下の余白
      const offsetTop = offset + stickyHeaderHeight; // StickyInnerの上部の余白には、追従ヘッダーの高さを加える
      const offsetBottom = offset;

      const top =
        // サイドバーがウィンドウ高より大きい場合、サイドバー下部まで表示される位置で追従させる
        window.innerHeight < stickyInnerHeight + offsetTop
          ? window.innerHeight - (stickyInnerHeight + offsetBottom)
          : offsetTop;
      setTop(top);
    };

    window.addEventListener('load', updateHeight);
    window.addEventListener('resize', updateHeight);
    requestAnimationFrame(updateHeight);

    return () => {
      window.removeEventListener('load', updateHeight);
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <Wrapper>
      {pathname !== '/' && (
        <>
          <Inner>
            <SidebarHeading>最新記事</SidebarHeading>
            <SidebarLatestArticles />
          </Inner>
          <Inner>
            <SidebarHeading>PICK UP タグ</SidebarHeading>
            <SidebarTags />
          </Inner>
        </>
      )}
      <StickyInner ref={ref} top={top}>
        <SidebarHeading>NIJIBOX Information</SidebarHeading>
        <SidebarInformation />
      </StickyInner>
    </Wrapper>
  );
};

export default Sidebar;
