import React from 'react';
import styled from 'styled-components';
import { Link, GatsbyLinkProps } from 'gatsby';

const StyledLink = styled(({ border, ...props }: StyledLinkProps) => (
  <Link {...props} />
))`
  display: inline-block;
  padding: 8px 15px;
  font-size: 14px;
  text-decoration: none;
  background: #fff;
  border: ${({ border }) => (border ? '1px solid #ddd' : 'none')};
  border-radius: 30px;

  @media (max-width: 767px) {
    padding: 8px 12px;

    /* margin: 10px 0 0 10px; */
    font-size: 13px;
  }

  &::before {
    content: '#';
  }
`;

// see: https://www.polynique.com/web-development/extend-gatsby-link-in-typescript/
type StyledLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> &
  Pick<Props, 'border'>;

type Props = {
  href: string;
  label: string;
  border?: boolean;
};

const Tag: React.FC<Props> = ({ href, label, border }) => (
  <StyledLink to={href} border={border}>
    {label}
  </StyledLink>
);

export default Tag;
