import React from 'react';
import styled from 'styled-components';

const Text = styled.p<{ isSticky?: boolean }>`
  line-height: 1.6;
  color: #666;
  font-size: ${(props) => (props.isSticky ? '10px' : '12px')}; 
  font-weight: ${(props) => (props.isSticky ? 'normal' : 'bold')}; 

  @media (max-width: 767px) {
    font-size: 10px;
    font-weight: normal;
  }
`;

type Props = {
  isSticky?: boolean;
};

const HeaderDescription: React.FC<Props> = ({ isSticky }) => {
  return (
    <Text isSticky={isSticky}>
      ニジボックスが運営する
      <br />
      エンジニアに向けた
      <br />
      キュレーションメディア
    </Text>
  );
};

export default HeaderDescription;
