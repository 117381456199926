import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import logo from '@/images/logo.png';

const Pc = styled.h1<{ isSticky: boolean }>`
  display: ${(props) => (props.isSticky ? 'none' : 'inline')};

  @media (max-width: 767px) {
    display: none;
  }
`;

const Sp = styled.h1<{ isSticky: boolean }>`
  display: ${(props) => (props.isSticky ? 'inline' : 'none')};

  @media (max-width: 767px) {
    display: inline;
  }
`;

type Props = {
  isSticky?: boolean;
};

const HeaderLogo: React.FC<Props> = ({ isSticky = false }) => {
  return (
    <Link to="/">
      <Pc isSticky={isSticky}>
        <img
          src={logo}
          alt="POSTD PRODUCED BY NIJIBOX"
          width={160}
          height={75}
        />
      </Pc>
      <Sp isSticky={isSticky}>
        <img
          src={logo}
          alt="POSTD PRODUCED BY NIJIBOX"
          width={96}
          height={45}
        />
      </Sp>
    </Link>
  );
};

export default HeaderLogo;
