import React from 'react';
import styled from 'styled-components';
import ToTopButton from '@/atoms/ToTopButton';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1400px;
  padding: 0 30px 30px;

  @media (max-width: 767px) {
    padding: 0 20px 20px;
  }
`;

const TopButtonWrapper: React.FC = () => (
  <Wrapper>
    <Inner>
      <ToTopButton />
    </Inner>
  </Wrapper>
);

export default TopButtonWrapper;
