import React from 'react';
import styled from 'styled-components';
import Copyright from '@/atoms/Copyright';
import FooterHeading from '@/molecules/FooterHeading';
import FooterNav from '@/molecules/FooterNav';

const Wrapper = styled.footer`
  background-color: #1b1b1b;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 45px 30px 18px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding: 40px 20px 20px;
  }
`;

const CopyrightWrapper = styled.div`
  margin-top: 84px;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 39px;
  }
`;

const Footer: React.FC = () => (
  <Wrapper>
    <Inner>
      <FooterHeading />
      <FooterNav />
      <CopyrightWrapper>
        <Copyright />
      </CopyrightWrapper>
    </Inner>
  </Wrapper>
);

export default Footer;
