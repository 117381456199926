import React from 'react';
import logoBlack from '@/images/logo_corp.png';
import logoWhite from '@/images/logo_corp_white.png';

type Props = {
  white?: boolean;
};

const CorpLogo: React.FC<Props> = ({ white }) => (
  <>
    {white ? (
      <img src={logoWhite} alt="NIJIBOX" width={100} height={37} />
    ) : (
      <img src={logoBlack} alt="NIJIBOX" width={100} height={37} />
    )}
  </>
);

export default CorpLogo;
