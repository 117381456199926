import React from 'react';
import styled from 'styled-components';

const ExternalLink = styled.a`
  display: block;

  img {
    width: 100%;
  }
`;

const ImagePc = styled.div`
  display: block;

  @media (max-width: 1399px) {
    display: none;
  }
`;

const ImageSp = styled.div`
  display: none;

  @media (max-width: 1399px) {
    display: block;
  }
`;

type Props = {
  href: string;
  alt: string;
  imgUrl: string;
  width?: number;
  height: number;
};

const SidebarImage: React.FC<Props> = ({
  href,
  alt,
  imgUrl,
  width = 310,
  height,
}) => (
  <ExternalLink href={href} target="_blank" rel="noopener noreferrer">
    <ImagePc>
      <img src={imgUrl} alt={alt} width={width} height={height} />
    </ImagePc>
    <ImageSp>
      <img src={imgUrl} alt={alt} />
    </ImageSp>
  </ExternalLink>
);

export default SidebarImage;
