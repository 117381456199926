import React from 'react';
import styled from 'styled-components';
import { Link, GatsbyLinkProps } from 'gatsby';
import { LinkType } from '@/services/links';

const StyledLink = styled(({ footer, ...props }: StyledLinkProps) => (
  <Link {...props} />
))`
  font-size: 13px;
  color: ${({ footer }) => (footer ? '#fff' : 'inherit')};
  text-decoration: none;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const ExternalLink = styled.a<Pick<Props, 'footer'>>`
  font-size: 13px;
  color: ${(props) => (props.footer ? '#fff' : 'inherit')};
  text-decoration: none;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const TagLink = styled(StyledLink)`
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 30px;
`;

const MoreLink = styled(StyledLink)`
  display: inline-block;

  &::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: 7px;
    content: '';
    border-top: ${(props) =>
      props.footer ? '2px solid #fff' : '2px solid #1b1b1b'};
    border-right: ${(props) =>
      props.footer ? '2px solid #fff' : '2px solid #1b1b1b'};
    transform: rotate(45deg);
  }
`;

// see: https://www.polynique.com/web-development/extend-gatsby-link-in-typescript/
type StyledLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> &
  Pick<Props, 'footer'>;

type Props = {
  heading?: string;
  link: LinkType;
  footer?: boolean;
};

const NavLink: React.FC<Props> = ({ heading, link, footer }) => {
  if (heading === 'PICK UP タグ') {
    if (footer) {
      return (
        <StyledLink
          to={`/tag/${link.slug}/`}
          footer={footer}
        >{`#${link.title}`}</StyledLink>
      );
    }
    return <TagLink to={`/tag/${link.slug}/`}>{`#${link.title}`}</TagLink>;
  }

  if (link.title === 'タグ一覧を見る') {
    return (
      <MoreLink to={link.slug} footer={footer}>
        {link.title}
      </MoreLink>
    );
  }

  return (
    <>
      {/https:/.exec(link.slug) ? (
        <ExternalLink
          href={link.slug}
          target="_blank"
          rel="noopener noreferrer"
          footer={footer}
        >
          {link.title}
        </ExternalLink>
      ) : (
        <StyledLink
          to={heading === 'CATEGORY' ? `/category/${link.slug}/` : link.slug}
          footer={footer}
        >
          {link.title}
        </StyledLink>
      )}
    </>
  );
};

export default NavLink;
