export type LinkType = {
  title: string;
  slug: string;
};

export const aboutLinks: LinkType[] = [
  {
    title: 'POSTDについて',
    slug: '/about/',
  },
  {
    title: 'お問い合わせ',
    slug: 'https://www.nijibox.jp/contacts/contact-form1',
  },
  {
    title: '利用規約',
    slug: '/about/terms/',
  },
  {
    title: 'プライバシーポリシー',
    slug: 'https://cdn.p.recruit.co.jp/terms/njb-t-1001/index.html',
  },
  {
    title: 'パーソナルデータ指針',
    slug: 'https://www.recruit.co.jp/privacy/personaldata/',
  },
];

export const companyLinks: LinkType[] = [
  {
    title: '会社概要',
    slug: 'https://www.nijibox.jp/about',
  },
  {
    title: '制作実績',
    slug: 'https://www.nijibox.jp/work',
  },
  {
    title: '実績資料ダウンロード',
    slug: 'https://www.nijibox.jp/docs-dl',
  },
];
