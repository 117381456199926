import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Tag from '@/atoms/Tag';
import NavLink from '@/atoms/NavLink';

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 9px;
  margin-left: -12px;

  @media (max-width: 767px) {
    margin-top: 7px;
  }
`;

const Item = styled.li`
  margin-top: 11px;
  margin-left: 12px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`;

const NavLinkWrapper = styled.div`
  margin-top: 22px;
`;

const query = graphql`
  query TagList {
    allSiteTag(filter: { is_pickup: { eq: true } }) {
      nodes {
        slug
        title
      }
    }
  }
`;

const SidebarTags: React.FC = () => {
  const { allSiteTag } = useStaticQuery<GatsbyTypes.TagListQuery>(query);

  return (
    <>
      <List>
        {allSiteTag.nodes.map((item) => {
          const slug = item.slug || '';
          return (
            <Item key={item.title}>
              <Tag href={`/tag/${slug}/`} label={item.title || ''} />
            </Item>
          );
        })}
      </List>
      <NavLinkWrapper>
        <NavLink link={{ title: 'タグ一覧を見る', slug: '/tag/' }} />
      </NavLinkWrapper>
    </>
  );
};

export default SidebarTags;
