import React from 'react';
import styled from 'styled-components';

const ExternalLink = styled.a`
  &:not(:first-child) {
    margin-left: 20px;
  }
`;

type Props = {
  href: string;
  imgSrc: string;
  alt: string;
};

const HeaderIconLink: React.FC<Props> = ({ href, imgSrc, alt }) => (
  <ExternalLink href={href} target="_blank" rel="noopener noreferrer">
    <img src={imgSrc} alt={alt} width={24} height={24} />
  </ExternalLink>
);

export default HeaderIconLink;
