import React from 'react';
import styled from 'styled-components';

type Props = {
  handleClick: () => void;
  active: boolean;
};

const Wrapper = styled.button`
  display: none;
  background: transparent;
  border: none;
  appearance: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Line = styled.div<Pick<Props, 'active'>>`
  width: 22px;
  height: 2px;
  background: #1b1b1b;
  transition: opacity 0.2s, transform 0.2s;

  &:nth-child(1) {
    transform: translateY(${(props) => (props.active ? '8px' : '0')})
      rotate(${(props) => (props.active ? '45deg' : '0')});
  }

  &:nth-child(2) {
    margin-top: 6px;
    opacity: ${(props) => (props.active ? '0' : '1')};
  }

  &:nth-child(3) {
    margin-top: 6px;
    transform: translateY(${(props) => (props.active ? '-8px' : '0')})
      rotate(${(props) => (props.active ? '-45deg' : '0')});
  }
`;

const HeaderNavButton: React.FC<Props> = ({ handleClick, active }) => (
  <Wrapper type="button" onClick={handleClick}>
    <Line active={active} />
    <Line active={active} />
    <Line active={active} />
  </Wrapper>
);

export default HeaderNavButton;
