import React from 'react';
import styled from 'styled-components';
import logo from '@/images/logo_white.png';

const Pc = styled.p`
  @media (max-width: 767px) {
    display: none;
  }
`;

const Sp = styled.p`
  display: none;

  @media (max-width: 767px) {
    display: inline;
  }
`;

const FooterLogo: React.FC = () => (
  <>
    <Pc>
      <img src={logo} alt="POSTD PRODUCED BY NIJIBOX" width={160} height={75} />
    </Pc>
    <Sp>
      <img src={logo} alt="POSTD PRODUCED BY NIJIBOX" width={130} height={61} />
    </Sp>
  </>
);

export default FooterLogo;
