import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-size: 13px;
  line-height: 1.6;
  color: #f2f2f2;

  @media (max-width: 767px) {
    font-size: 10px;
  }
`;

const FooterDescription: React.FC = () => (
  <Text>
    POSTDはニジボックスが運営する、
    <br />
    エンジニアに向けたキュレーションメディアです。
  </Text>
);

export default FooterDescription;
