import React from 'react';
import styled from 'styled-components';

const Text = styled.h2<Props>`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.footer ? '#fff' : 'inherit')};

  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

type Props = {
  footer?: boolean;
};

const NavLinkHeading: React.FC<Props> = ({ children, footer }) => (
  <Text footer={footer}>{children}</Text>
);

export default NavLinkHeading;
