import React from 'react';
import styled from 'styled-components';

const Text = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: calc(20 / 14);

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: calc(18 / 14);
  }
`;

const SidebarHeading: React.FC = ({ children }) => <Text>{children}</Text>;

export default SidebarHeading;
