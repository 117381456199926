/**
 * 汎用的なパーツ、スタイルを読み込む上位コンポーネント
 */
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import objectFitImages from 'object-fit-images';
import Header from '@/organisms/Header';
import Footer from '@/organisms/Footer';
import TopButtonWrapper from '@/organisms/TopButtonWrapper';

export const GlobalStyle = createGlobalStyle`
  /*
  html5doctor.com Reset Stylesheet
  v1.6.1
  Last Updated: 2010-09-17
  Author: Richard Clark - http://richclarkdesign.com
  Twitter: @rich_clark
  */

  html,
  body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    padding: 0;
    margin: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    border: 0;
    outline: 0;
  }

  body {
    line-height: 1;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  /* nav ul {
    list-style: none;
  } */

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  a {
    padding: 0;
    margin: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  /* change colours to suit your needs */
  ins {
    color: #000;
    text-decoration: none;
    background-color: #ff9;
  }

  /* change colours to suit your needs */
  mark {
    font-style: italic;
    font-weight: bold;
    color: #000;
    background-color: #ff9;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title],
  dfn[title] {
    cursor: help;
    border-bottom: 1px dotted;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  /* change border colour to suit your needs */
  hr {
    display: block;
    height: 1px;
    padding: 0;
    margin: 1em 0;
    border: 0;
    border-top: 1px solid #ccc;
  }

  input,
  select {
    vertical-align: middle;
  }

  /* 独自スタイル */
  html {
    font-family: 'Helvetica Neue',
      Arial,
      YuGothic,
      'Yu Gothic Medium',
      'Yu Gothic',
      'Hiragino Kaku Gothic ProN',
      'Hiragino Sans',
      Meiryo,
      sans-serif;
    background: #f6f6f6;
  }

  * {
    box-sizing: border-box;
    line-height: 1;
  }

  *:not(code, code *) {
    color: #1b1b1b;
  }

  a:hover {
    color: #767676;
    opacity: 0.8;
  }

  ul,
  ol {
    list-style: none;
  }
`;

/**
 * 渡すプロパティで表示のカスタマイズができる
 * fullwidth: SP版のとき、コンテンツの横paddingをなくして全面表示 (記事や固定ページなど)
 */
type Props = {
  fullwidth?: boolean;
};

const Wrapper = styled.main<Pick<Props, 'fullwidth'>>`
  display: flex;
  justify-content: center;
  padding: 50px 30px 60px;

  @media (max-width: 767px) {
    padding: ${(props) => (props.fullwidth ? '74px 0 50px' : '104px 20px 50px')};
  }
`;

const Inner = styled.section`
  width: 100%;
  max-width: 1340px;
`;

const Layout: React.FC<Props> = ({ children, fullwidth }) => {
  // @ts-expect-error パッケージ側の型定義が誤っており引数を要求されるため、ここだけ型エラーを止めている
  objectFitImages();

  return (
    <>
      <GlobalStyle />
      <Header />
      <Wrapper fullwidth={fullwidth}>
        <Inner>{children}</Inner>
      </Wrapper>
      <TopButtonWrapper />
      <Footer />
    </>
  );
};

export default Layout;
