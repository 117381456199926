import React from 'react';
import styled from 'styled-components';
import Copyright from '@/atoms/Copyright';
import CorpLogo from '@/atoms/CorpLogo';

const Wrapper = styled.div`
  text-align: center;
`;

const CopyrightWrapper = styled.div`
  margin-top: 12px;
`;

const HeaderCorpInfo: React.FC = () => (
  <Wrapper>
    <CorpLogo />
    <CopyrightWrapper>
      <Copyright />
    </CopyrightWrapper>
  </Wrapper>
);

export default HeaderCorpInfo;
