import React from 'react';
import styled from 'styled-components';
import HeaderIconLink from '@/atoms/HeaderIconLink';
import iconFeedly from '@/images/icon_feedly.png';
import iconRss from '@/images/icon_rss.png';
import iconTwitter from '@/images/icon_twitter.png';
import iconFacebook from '@/images/icon_facebook.png';

const Wrapper = styled.div`
  display: flex;
`;

const HeaderIcons: React.FC = () => (
  <Wrapper>
    <HeaderIconLink
      href="https://feedly.com/i/subscription/feed%2Fhttps%3A%2F%2Fpostd.cc%2Ffeed%2F"
      imgSrc={iconFeedly}
      alt="Feedly"
    />
    <HeaderIconLink href="https://postd.cc/feed/" imgSrc={iconRss} alt="RSS" />
    <HeaderIconLink
      href="https://twitter.com/POSTDcc"
      imgSrc={iconTwitter}
      alt="Twitter"
    />
    <HeaderIconLink
      href="https://www.facebook.com/postdcc"
      imgSrc={iconFacebook}
      alt="Facebook"
    />
  </Wrapper>
);

export default HeaderIcons;
