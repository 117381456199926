import React from 'react';
import styled from 'styled-components';
import FooterLogo from '@/atoms/FooterLogo';
import FooterDescription from '@/atoms/FooterDescription';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DescriptionWrapper = styled.div`
  margin-left: 30px;

  @media (max-width: 767px) {
    margin: 11px 0 0;
  }
`;

const FooterHeading: React.FC = () => (
  <Wrapper>
    <FooterLogo />
    <DescriptionWrapper>
      <FooterDescription />
    </DescriptionWrapper>
  </Wrapper>
);

export default FooterHeading;
