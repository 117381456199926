import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import defaultOgpImage from '@/images/og.png';
import AppIcon from '@/images/icon_app.png';
import favicon from '@/images/favicon.ico';

type Props = {
  uniqueOgImage?: string;
  uniqueTitle?: string;
  uniqueDescription?: string;
  origin: string;
  url: string;
};

const Seo: React.FC<Props> = ({
  uniqueOgImage,
  uniqueTitle,
  uniqueDescription,
  origin,
  url,
}) => {
  const { site } = useStaticQuery<GatsbyTypes.SeoQuery>(query);
  const image = !uniqueOgImage ? defaultOgpImage : uniqueOgImage;
  const siteUrl =
    origin ||
    (typeof site?.siteMetadata?.siteUrl === 'string'
      ? site?.siteMetadata?.siteUrl
      : '');

  const titleSuffix = ' | POSTD';
  const title = !uniqueTitle
    ? site?.siteMetadata?.title
    : `${uniqueTitle}${titleSuffix}`;

  const description = !uniqueDescription
    ? site?.siteMetadata?.description
    : uniqueDescription;

  const ogUrl = /https?:\/\/.+/.test(url) ? url : `${siteUrl}${url}`;
  const ogImage = /https?:\/\/.+/.test(image) ? image : `${siteUrl}${image}`;

  return (
    <Helmet>
      <html lang="ja" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={site?.siteMetadata?.title} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={ogImage} />
      <link rel="shortcut icon" type="image/ico" href={favicon} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={AppIcon} />
      <meta name="apple-mobile-web-app-title" content="POSTD" />
      <link rel="apple-touch-icon-precomposed" href={AppIcon} />
    </Helmet>
  );
};

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        description
        title
        siteUrl
      }
    }
  }
`;

export default Seo;
