import React from 'react';
import styled from 'styled-components';

const Text = styled.small`
  display: block;
  font-size: 11px;
  color: #767676;
`;

const Copyright: React.FC = () => (
  <Text>©NIJIBOX CO.,LTD ALL RIGHTS RESERVED</Text>
);

export default Copyright;
