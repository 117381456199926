import React from 'react';
import styled from 'styled-components';
import NavLinkHeading from '@/atoms/NavLinkHeading';
import NavLink from '@/atoms/NavLink';
import { LinkType } from '@/services/links';

const Wrapper = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
  }
`;

const LinkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TagLinkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
`;

const LinkItem = styled.li`
  flex-basis: 50%;
  margin-top: 17px;
`;

const TagLinkItem = styled.li`
  margin: 17px 0 0 10px;
`;

const MoreLinkWrapper = styled.div`
  margin-top: 20px;
`;

type Props = {
  heading: string;
  links: LinkType[];
};

const HeaderNavLinks: React.FC<Props> = ({ heading, links }) => (
  <Wrapper>
    <NavLinkHeading>{heading}</NavLinkHeading>
    {/* タグのときは見た目が変わる */}
    {heading === 'PICK UP タグ' ? (
      <>
        <TagLinkList>
          {links.map((link) => (
            <TagLinkItem key={link.title}>
              <NavLink heading={heading} link={link} />
            </TagLinkItem>
          ))}
        </TagLinkList>
        <MoreLinkWrapper>
          <NavLink
            heading=""
            link={{ title: 'タグ一覧を見る', slug: '/tag/' }}
          />
        </MoreLinkWrapper>
      </>
    ) : (
      <LinkList>
        {links.map((link) => (
          <LinkItem key={link.title}>
            <NavLink heading={heading} link={link} />
          </LinkItem>
        ))}
      </LinkList>
    )}
  </Wrapper>
);

export default HeaderNavLinks;
