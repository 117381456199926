import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import SidebarImage from '@/atoms/SidebarImage';
import { BannerType } from '@/services/banners';

const query = graphql`
  query SidebarBanners {
    allEventBanner: allSiteBanner(
      sort: { order: ASC, fields: index }
      filter: { target: { eq: "event" } }
    ) {
      nodes {
        url
        image
        id
        expired
      }
    }
    allConnpassBanner: allSiteBanner(
      sort: { order: ASC, fields: index }
      filter: { target: { eq: "connpass" } }
    ) {
      nodes {
        url
        image
        id
        expired
      }
    }
    allCompanyBanner: allSiteBanner(
      sort: { order: ASC, fields: index }
      filter: { target: { eq: "company" } }
    ) {
      nodes {
        url
        image
        id
        expired
      }
    }
  }
`;

const Item = styled.li`
  margin-top: 18px;
`;

const SidebarInformation: React.FC = () => {
  const { allEventBanner, allConnpassBanner, allCompanyBanner } =
    useStaticQuery<GatsbyTypes.SidebarBannersQuery>(query);
  const now = new Date();
  const eventBanners = (allEventBanner.nodes as BannerType[]).filter(
    (banner) => new Date(banner.expired) > now,
  );
  const connpassBanners = (allConnpassBanner.nodes as BannerType[]).filter(
    (banner) => new Date(banner.expired) > now,
  );
  if (connpassBanners.length > 1) {
    connpassBanners.pop();
  }
  const companyBanners = allCompanyBanner.nodes as BannerType[];

  return (
    <ul>
      {[...eventBanners, ...connpassBanners, ...companyBanners].map(
        (banner) => (
          <Item key={banner.id}>
            <SidebarImage
              href={banner.url}
              // eslint-disable-next-line import/no-dynamic-require,global-require,@typescript-eslint/no-var-requires
              imgUrl={require(`@/images/${banner.image}`).default}
              alt={banner.alt}
              height={banner.height}
            />
          </Item>
        ),
      )}
    </ul>
  );
};

export default SidebarInformation;
